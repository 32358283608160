<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      사용자조회
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="[$emit('hide'),init(),initCust(),selectUser()]"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div class="box-ct d-flex gray-box">
        <div class>
          <div class="form-inp sm">
            <v-select
              :items="ASP_CUST_KEY"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="ASP_NEWCUST_KEY"
              :readonly="userInfo.NO_CUST_KEY === 'true' || userInfo.NO_CUST_KEY === '' || userInfo.NO_CUST_KEY === undefined"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-text-field
            class="form-inp sm ml-2"
            name="name"
            label="ID"
            outlined
            hide-details
            v-model="USER_NEWID"
          >
            <template v-slot:label>ID</template>
          </v-text-field>
          <v-text-field
            class="form-inp sm ml-2"
            name="name"
            label="이름"
            outlined
            hide-details
            v-model="USER_NEWNM"
          >
            <template v-slot:label>이름</template>
          </v-text-field>
          <v-text-field
            class="form-inp sm ml-2"
            name="name"
            label="닉네임"
            outlined
            hide-details
            v-model="USER_NEWNICK"
          >
            <template v-slot:label>닉네임</template>
          </v-text-field>
          <div class="form-inp sm ml-2">
            <v-select
              :items="USER_ATTR_A"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              placeholder="선택하세요"
              v-model="USER_NEWATTR_A"
            >
              <template v-slot:label>센터</template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="USER_ATTR_B"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              placeholder="선택하세요"
              v-model="USER_NEWATTR_B"
            >
              <template v-slot:label>부서</template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="USER_ATTR_C"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              placeholder="선택하세요"
              v-model="USER_NEWATTR_C"
            >
              <template v-slot:label>팀</template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="USER_ATTR_D"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              placeholder="선택하세요"
              v-model="USER_NEWATTR_D"
            >
              <template v-slot:label>기타</template>
            </v-select>
          </div>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" v-on:click="selectUser">조회</v-btn>
        </div>
      </div>
      <div class="box-ct mt-3">
        <v-data-table
          dense
          height="521px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="30"
          item-key="index"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          calculate-widths
          single-select
          @page-count="pageCount = $event"
          @click:row="rowClick1"
          @dblclick:row="rowClick2"
        ></v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
        <p class="caption text-right">
          <strong class="clr-error">{{ gridDataText.length }}</strong>건의 사용자가 검색되었습니다.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/store/apiUtil.js';
import { mixin } from "@/mixin/mixin.js";

export default {
  name: "MENU_MP02", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  props: {
    userInfo: {
      type: Object,
      value: {},
    },
  },
  computed: {
  },
  components: {
  },
  data() {
    return {
      gridDataHeaders: [
        {
          text: 'No',
          align: 'center',
          value: 'index',
          width: '50px',
          sortable: false,
        },
        { text: "ID", value: 'USER_ID', align: 'center', sortable: false, },
        { text: "이름", value: 'USER_NM', align: 'center', sortable: false, },
        { text: "닉네임", value: 'USER_NICK', align: 'center', sortable: false, },
        { text: "센터", value: 'ATTR_DIV_NM_A', align: 'center', sortable: false, },
        { text: "부서", value: 'ATTR_DIV_NM_B', align: 'center', sortable: false, },
        { text: "팀", value: 'ATTR_DIV_NM_C', align: 'center', sortable: false, },
        { text: "기타", value: 'ATTR_DIV_NM_D', align: 'center', sortable: false, },
      ],
      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,

      ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company,

      USER_ATTR_A: [
        {
          CD: '',
          CD_NM: '전체'
        },
      ],

      USER_ATTR_B: [
        {
          CD: '',
          CD_NM: '전체'
        },
      ],

      USER_ATTR_C: [
        {
          CD: '',
          CD_NM: '전체'
        },
      ],

      USER_ATTR_D: [
        {
          CD: '',
          CD_NM: '전체'
        },
      ],

      //ASP_NEWCUST_KEY: this.userInfo.ASP_CUST_KEY,
      ASP_NEWCUST_KEY: "001",

      USER_NEWID: '',
      USER_NEWNM: '',
      USER_NEWNICK: '',

      USER_NEWATTR_A: '',
      USER_NEWATTR_B: '',
      USER_NEWATTR_C: '',
      USER_NEWATTR_D: '',

      READ_YN: '',
    };
  },

  created() {
    //this.ASP_NEWCUST_KEY = this.userInfo.ASP_CUST_KEY;
    //this.ASP_CUST_KEY = this.$store.getters['userStore/GE_USER_ROLE'].company,
    this.ASP_NEWCUST_KEY = "001";
    this.ASP_CUST_KEY = "001",
    this.USER_NEWID = this.userInfo.USER_ID;
    this.USER_NEWNM = this.userInfo.USER_NM;
    this.USER_NEWNICK = this.userInfo.USER_NICK;
    this.selectUser();
    this.USER_ATTR_A = [{CD: '', CD_NM: '전체'}];
    this.USER_ATTR_B = [{CD: '', CD_NM: '전체'}];
    this.USER_ATTR_C = [{CD: '', CD_NM: '전체'}];
    this.USER_ATTR_D = [{CD: '', CD_NM: '전체'}];
    this.getAttrDiv('A');
    this.getAttrDiv('B');
    this.getAttrDiv('C');
    this.getAttrDiv('D');
  },

  methods: {
    init() {
      //this.ASP_NEWCUST_KEY = this.userInfo.ASP_CUST_KEY;
      this.ASP_NEWCUST_KEY = "001";

      this.USER_NEWID = '';
      this.USER_NEWNM = '';
      this.USER_NEWNICK = '';

      this.USER_NEWATTR_A = '';
      this.USER_NEWATTR_B = '';
      this.USER_NEWATTR_C = '';
      this.USER_NEWATTR_D = '';
    },

    initCust(){
      this.userInfo.NO_CUST_KEY = 'true';
    },

    //사용자 소속 콤보박스 생성
    getAttrDiv(attr) {
      api.post("api/palette/common/get-attr-div/list",   //api url입력
      {
        //ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
        ASP_NEWCUST_KEY: "001",
        ATTR_CD: attr,
        ATTR_DIV_CD: '',
      },
      {head: {
        "SERVICE" : "palette.common.get-attr-div",
        "METHOD" : "list",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        console.log(response.data.DATA);
        if (attr == 'A'){
          this.USER_ATTR_A = [...this.USER_ATTR_A, ...response.data.DATA];
        } else if (attr == "B") {
          this.USER_ATTR_B = [...this.USER_ATTR_B, ...response.data.DATA];
        } else if (attr == "C") {
          this.USER_ATTR_C = [...this.USER_ATTR_C, ...response.data.DATA];
        } else if (attr == "D") {
          this.USER_ATTR_D = [...this.USER_ATTR_D, ...response.data.DATA];
        }
      })
      .catch((err) => {
        alert(err);
      })
    },

    selectUser() {
      api.post("api/setting/agent/manage/user-info-inqire-popup/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
          USER_ID: this.USER_NEWID,
          USER_NM: this.USER_NEWNM,
          USER_NICK: this.USER_NEWNICK,
          USER_ATTR_A: this.USER_NEWATTR_A,
          USER_ATTR_B: this.USER_NEWATTR_B,
          USER_ATTR_C: this.USER_NEWATTR_C,
          USER_ATTR_D: this.USER_NEWATTR_D,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {
          head: {
            "SERVICE": "setting.agent.manage.user-info-inqire-popup",
            "METHOD": "inqire",
            "TYPE": "BIZ_SERVICE",
            "ROW_CNT": "200",
            "PAGES_CNT": "1",
          }
        })
        .then((response) => {
          this.gridDataText = response.data.DATA;
          for (var i = 0; i < this.gridDataText.length; i++) {
            this.gridDataText[i]["index"] = i + 1;
          }
          this.page = 1;
        })
        .catch((err) => {
          alert(err);
        })
    },

    //Table row 클릭이벤트
    rowClick2(e, { item }) {
      this.userInfo.USER_ID = item.USER_ID;
      this.userInfo.USER_NM = item.USER_NM;
      this.userInfo.USER_NICK = item.USER_NICK;

      this.$emit("selectUser", this.userInfo);
      this.init();
      this.initCust()
      this.selectUser();
    },

    //Table row 클릭이벤트
    rowClick1(item, row) {
      if (item != null) {
        this.activeRow(row, true);
      }
    },

    activeRow(row, value) {
      console.log(row);
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

  },

  mounted() {
  },

  computed: {},

  watch: {
    userInfo() {
      this.init();

      //this.ASP_CUST_KEY = this.$store.getters['userStore/GE_USER_ROLE'].company,
      //this.ASP_NEWCUST_KEY = this.userInfo.ASP_CUST_KEY;
      this.ASP_CUST_KEY = "001",
      this.ASP_NEWCUST_KEY = "001";
      this.USER_NEWID = this.userInfo.USER_ID;
      this.USER_NEWNM = this.userInfo.USER_NM;
      this.USER_NEWNICK = this.userInfo.USER_NICK;
      this.selectUser();
      
      this.USER_ATTR_A = [{ CD: '', CD_NM: '전체' }];
      this.USER_ATTR_B = [{ CD: '', CD_NM: '전체' }];
      this.USER_ATTR_C = [{ CD: '', CD_NM: '전체' }];
      this.USER_ATTR_D = [{ CD: '', CD_NM: '전체' }];
      this.getAttrDiv('A');
      this.getAttrDiv('B');
      this.getAttrDiv('C');
      this.getAttrDiv('D');
    }
  }
};
</script>

<style></style>
