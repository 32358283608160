<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      아웃바운드 상세조회
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div class="d-flex gray-box">
        <div >
          <div>
            <div class="form-inp sm">
              <v-select
                :items="aspCustList"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="회사구분"
                placeholder="선택하세요"
                v-model="getData.ASP_CUST_KEY"
                disabled
              ></v-select>
            </div>
            <v-text-field
              class="form-inp ml-2"
              name="name"
              label="아웃바운드명"
              outlined
              hide-details
              disabled
              v-model="getData.CAM_NM"
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="name"
              label="사용자ID"
              outlined
              hide-details
              v-model="CSLT_ID"
            >
              <template v-slot:label>
                사용자ID
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="name"
              label="사용자 이름"
              outlined
              hide-details
              v-model="CSLT_NM"
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="name"
              label="사용자 닉네임"
              outlined
              hide-details
              v-model="CSLT_NICK"
            ></v-text-field>
            <!-- 조회 버튼 -->
            <v-btn 
              v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')"
              dark small
              class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain
              @click="showDialog('MP02')">
              <v-icon small class="svg-main-searh white"></v-icon>
            </v-btn>
            <!-- 새로고침 버튼 -->
            <v-btn 
              v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')"
              dark small
              class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px"
              @click="clearUserData"
              plain
              >
              <v-icon small class="svg-main-refresh"></v-icon>
            </v-btn>
          </div>
          <div>
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp icon-calendar"
                  append-icon="svg-calendar"
                  disabled
                ></v-text-field>
              </template>
              <div class="datepicker-range">
                <v-date-picker v-model="getData.CAM_BEGIN_DATE" no-title dark color="#F7AFAE"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
                ></v-date-picker>
                <v-date-picker
                  v-model="getData.CAM_EOT_DATE"
                  dark
                  color="#F7AFAE"
                  no-title
                  show-current="false"
                  :min="getData.CAM_BEGIN_DATE"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
                ></v-date-picker>
              </div>
              <div class="text-center pt-3 pb-3">
                <v-btn outlined class="btn-default" @click="menu1 = false">확인</v-btn>
              </div>
            </v-menu>
            <div class="form-inp sm ml-2">
              <v-select
                v-model="FISH_TY"
                :items="mixin_common_code_get(this.common_code, 'OU003','전체')"
                label="처리결과"
                outlined
                filled
                dense
                background-color="white"
              ></v-select>
            </div>
            <v-text-field
              class="form-inp lg ml-2"
              name="name"
              label="고객ID"
              outlined
              hide-details
              v-model="CUST_NO"
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="name"
              label="고객 이름"
              outlined
              hide-details
              v-model="CUST_NM"
            ></v-text-field>
            <!-- 조회 버튼 -->
            <v-btn 
              v-if="this.mixin_set_btn(this.$options.name, 'btnPopupCus')"
              dark small
              class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain
              @click="showDialog('MP01')">
              <v-icon small class="svg-main-searh white"></v-icon>
            </v-btn>
            <!-- 새로고침 버튼 -->
            <v-btn 
              v-if="this.mixin_set_btn(this.$options.name, 'btnInitCus')"
              dark small
              class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px"
              @click="clearCustData"
              plain
              >
              <v-icon small class="svg-main-refresh"></v-icon>
            </v-btn>
            <!-- 사용자정보 모달창 -->
            <div>
              <v-dialog
                max-width="1400"
                max-height="1200"
                v-model="DialogMP02"
                content-class="square-modal"
              >
                <dialog-MP02
                  headerTitle="사용자정보"
                  @hide="hideDialog('MP02')"
                  :userInfo="userInfo"
                  @input="userInfo = $event"
                  @selectUser="[hideDialog('MP02'), setUserData()]"
                ></dialog-MP02>
                <template v-slot:body></template>
              </v-dialog>
            </div>
            <!-- 사용자정보 모달창 -->
            <!-- 고객정보 모달창 -->
            <div>
              <v-dialog
                max-width="1400"
                max-height="1200"
                v-model="DialogMP01"
                content-class="square-modal"
              >
                <dialog-MP01
                  headerTitle="고객정보"
                  @hide="hideDialog('MP01')"
                  :cusInfo="cusInfo"
                  @input="cusInfo = $event"
                  @selectCus="[hideDialog('MP01'), setCustData()]"
                ></dialog-MP01>
                <template v-slot:body></template>
              </v-dialog>
            </div>
            <!-- 고객정보 모달창 -->

          </div>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="selectObndIngList">조회</v-btn>
        </div>
      </div>
      <!-- 조회테이블 -->
      <h2 class="tit-h2 d-flex mt-3">아웃바운드 리스트</h2>
      <div class="box-ct mt-3">
        <v-data-table
          dense
          height="490px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="30"
          item-key="index"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          @page-count="pageCount = $event"
        >
          <template v-slot:item.CUST_NO="{ item }">
            <div>{{ juminFormat(item.CUST_NO) }}</div>
          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import api from "@/store/apiUtil.js";
import DialogMP01 from "./MP01.vue"
import DialogMP02 from "./MP02.vue"
import { mapGetters } from "vuex";
let jsonObj;
export default {
  name: "MENU_M010101P01", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  props: {
    gridDetailData: {
      type: Object,
    },
  },
  computed: {
    dateRangeText() {
      let newStartDate, newEndDate;
      newStartDate = this.getData.CAM_BEGIN_DATE;
      newEndDate = this.getData.CAM_EOT_DATE;
      let result = newStartDate + " ~ " + newEndDate;
      return result;
    },
    getData() {
      return this.gridDetailData;
    },
    ...mapGetters({
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
  components: {
    DialogMP01,
    DialogMP02
  },
  data: () => ({
    menu1: false,
    userInfo: {},
    cusInfo: {},
    DialogMP01: false,
    DialogMP02: false,
    common_code: [],
    CSLT_ID: "",
    CSLT_NM: "", //사용자 이름
    CSLT_NICK: "", //사용자 닉네임
    CUST_NO: "", //고객 ID
    CUST_NM: "", //고객 이름
    FISH_TY: "", //처리결과
    ASP_NEWCUST_KEY: "001",
    CAM_ID: "", //아웃바운드 ID
    CAM_NM: "", //아웃바운드명
    gridDataHeaders: [
      { text: "No", align: "center", value: "ROW_NUMBER", width: "50px", sortable: false },
      { text: "주민번호", align: "center", value: "CUST_NO", width: "200px", sortable: false },
      { text: "고객명", align: "center", value: "CUST_NM", width: "100px", sortable: false },
      { text: "사용자명", align: "center", value: "CSLT_NM", width: "200px", sortable: false },
      { text: "집전화", align: "center", value: "HOUSE_TEL_NO", width: "200px", sortable: false },
      { text: "회사전화", align: "center", value: "CO_TEL_NO", width: "200px", sortable: false },
      { text: "핸드폰", align: "center", value: "MOBIL_NO", width: "200px", sortable: false },
      { text: "처리결과", align: " d-none", value: "FISH_TY", width: "200px", sortable: false },
      // { text: "처리결과", align: "center", value: "FISH_TY", width: "200px", sortable: false },
      { text: "영업일", align: "center", value: "CNTC_DATE", width: "200px", sortable: false },
      { text: "당일시도횟수", align: "center", value: "CNTC_CNT", width: "200px", sortable: false },
      { text: "총시도횟수", align: "center", value: "TRY_CNT", width: "200px", sortable: false },
      { text: "최종시도일", align: "center", value: "CHNG_DTIM", width: "200px", sortable: false },
      { text: "상담이력", align: "center", value: "CNSL_CNTN", width: "200px", sortable: false },
      { text: "녹취키", align: "center", value: "RDWT_ID", width: "200px", sortable: false },
      //hide column
      { text: "아웃바운드ID", align: " d-none", value: "CAM_ID", width: "200px", sortable: false },
      { text: "주민번호(더미)", align: " d-none", value: "CUST_NO", width: "200px", sortable: false },
      { text: "집전화(더미)", align: " d-none", value: "HOUSE_TEL_NO", width: "200px", sortable: false },
      { text: "회사전화(더미)", align: " d-none", value: "CO_TEL_NO", width: "200px", sortable: false },
      { text: "핸드폰(더미)", align: " d-none", value: "MOBIL_NO", width: "200px", sortable: false },
      { text: "처리결과", align: " d-none", value: "O_FISH_TY", width: "200px", sortable: false },
      { text: "증권번호", align: " d-none", value: "SECU_NO", width: "200px", sortable: false },
      { text: "녹취시간", align: " d-none", value: "CNSL_SAVE_TIME", width: "200px", sortable: false },
      { text: "녹취시작시간", align: " d-none", value: "CNSL_BEGIN_TIME", width: "200px", sortable: false },
    ],
    gridDataText: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    totalVisible: 10,
  }),
  methods: {
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    setGridText(jsonData) {
      jsonObj = JSON.parse(jsonData); //json string data를 object 형태로 파싱
      this.gridDataText = jsonObj;
    },
    showDialog(type) {
      if (type == 'MP01') {
        let cus = { ASP_CUST_KEY: this.getData.ASP_CUST_KEY, CUST_NO : this.CUST_NO, CUST_NM : this.CUST_NM };
        this.cusInfo = cus;
      } else if (type == 'MP02') {
        let user = { ASP_CUST_KEY: this.getData.ASP_CUST_KEY, USER_ID : this.CSLT_ID, USER_NM : this.CSLT_NM, USER_NICK : this.CSLT_NICK };
        this.userInfo = user;
      }
      this[`Dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`Dialog${type}`] = false;
    },
    setUserData() {
      this.CSLT_ID = this.userInfo.USER_ID;
      this.CSLT_NM = this.userInfo.USER_NM;
      this.CSLT_NICK = this.userInfo.USER_NICK;
    },
    setCustData() {
      this.CUST_NO = this.cusInfo.CUST_NO;
      this.CUST_NM = this.cusInfo.CUST_NM;
    },
    clearUserData() {
      this.CSLT_ID = "";
      this.CSLT_NM = "";
      this.CSLT_NICK = "";
    },
    clearCustData() {
      this.CUST_NO = "";
      this.CUST_NM = "";
    },
    async selectObndIngList() {
      //props를 통해 전달받은 데이터를 jsonData에 세팅
      let jsonData = {
        ASP_NEWCUST_KEY: this.getData.ASP_CUST_KEY,
        CAM_NM: this.getData.CAM_NM,  //아웃바운드명
        CAM_ID: this.getData.CAM_ID,  //아웃바운드ID
        START_OBND_DATE: this.getData.CAM_BEGIN_DATE, //시작일자
        END_OBND_DATE: this.getData.CAM_EOT_DATE, //종료일자
        FISH_TY: this.FISH_TY,  //처리결과
        CSLT_ID: this.CSLT_ID,  //사용자ID
        CSLT_NM: this.CSLT_NM,  //사용자명
        CSLT_NICK: this.CSLT_NICK,  //사용자닉네임
        CUST_NO: this.CUST_NO,  //고객ID
        CUST_NM: this.CUST_NM,  //고객명
      };

      let URLData = "/api/phone/dashboard/outbound/progrs-sttus-popup/inqire";
      let HEADER_SERVICE = "phone.dashboard.outbound.progrs-sttus-popup";
      let HEADER_METHOD = "inqire";
      let HEADER_TYPE = "BIZ_SERVICE";
      let HEADER_ROW_CNT = 50;
      let HEADER_PAGES_CNT = 1;
      //api 호출 부분
      await api
        .post(URLData, jsonData, {
          head: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            ROW_CNT: HEADER_ROW_CNT,
            PAGES_CNT: HEADER_PAGES_CNT,
          },
        })
        .then((response) => {
          this.setGridText(JSON.stringify(response.data.DATA));
        })
        .catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.", "error");
          console.log(err);
        });
    },
    juminFormat(num) {
      //주민 번호 형식 만들기
      var juminNum = num.replace(/-/g, '').replace(/(\d{6})(\d{7})/g, '$1-$2');
      return juminNum;
    },
  },
  async mounted() {
    let codeName = ["OU003"];
    this.common_code = await this.mixin_common_code_get_all(codeName);
  },
};
</script>

<style></style>
