<template>
  <div class="modal-wrap">
  <h1 class="tit-h1 d-flex">
    고객정보
    <div class="ml-auto align-self-center">
      <v-icon x-small class="svg-close20" @click="[$emit('hide'),init(),selectCus()]"></v-icon>
    </div>
  </h1>
  <div class="modal-box">
      <div class="box-ct d-flex gray-box">
        <div class="d-flex align-center">
          <div class="form-inp sm">
            <v-select
              :items="ASP_CUST_KEY"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="ASP_NEWCUST_KEY"
              readonly
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-text-field
            class="form-inp sm ml-2"
            name="name" label="고객명" outlined hide-details
            v-model="CUS_NEWNM">

            <template v-slot:label>
              고객명
            </template>
          </v-text-field>
          <v-checkbox
            :on-icon="'svg-chk-on'"
            :off-icon="'svg-chk-off'"
            label="기업명유사검색"
            class="d-inline-flex  ml-2"
            v-model="SRH_SIM"></v-checkbox>
          <v-text-field
            class="form-inp sm ml-2"
            name="name" label="생년월일" outlined hide-details
            v-model="CUS_NEWBIRTH">

            <template v-slot:label>
              생년월일
            </template>
          </v-text-field>
          <div class="form-inp sm ml-6">
            <v-select
              :items="PHONE_NUM"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              label="전화번호1"
              placeholder="선택하세요"
              v-model="PHONE_NEWNUM1"
            >
              <template v-slot:label>
                전화번호
              </template>
            </v-select>
          </div>
          -
          <v-text-field
            class="form-inp sm"
            name="name" label="전화번호2" outlined hide-details
            v-model="PHONE_NEWNUM2">

            <template v-slot:label>
              전화번호
            </template>
          </v-text-field>
          -
          <v-text-field
            class="form-inp sm"
            name="name" label="전화번호3" outlined hide-details
            v-model="PHONE_NEWNUM3">

            <template v-slot:label>
              전화번호
            </template>
          </v-text-field>
        </div>

        <div class="ml-auto align-self-center ">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" v-on:click="selectCus">조회</v-btn>
        </div>
    </div>
  <div class="mt-3">
    <v-data-table
      dense
      height="521px"
      :headers="gridDataHeaders"
      :items="gridDataText"
      :items-per-page="30"
      item-key="index"
      :page.sync="page"
      hide-default-footer
      class="grid-default"
      calculate-widths
      @page-count="pageCount = $event"
      @click:row="rowClick1"
      @dblclick:row="rowClick2"
      single-select
    >
    </v-data-table>
    <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
        <p class="caption text-right">
          <strong class="clr-error">{{ gridDataText.length }}</strong
          >건의 고객이 검색되었습니다.
        </p>
    </div>
  </div>
  </div>
</template>

<script>
import api from '@/store/apiUtil.js';
import { mixin } from "@/mixin/mixin.js";

export default {
  name: "MENU_MP01", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  props: {
    cusInfo: {
      type: Object,
      value:{},
    },
  },
  components: {
  },
  data() {
    return {
      gridDataHeaders: [
        {
          text: 'No',
          align: 'center',
          value: 'index',
          width: '50px',
          sortable: false,
        },
        {text:"고객구분", value: 'CUSTOMER_SECTION', align: 'center', sortable: false, },
        {text:"고객상태", value: 'CUSTOMER_STATE', align: 'center', sortable: false, },
        {text:"고객유형", value: 'CUSTOMER_TYPE_CD', align: 'center', sortable: false, },
        {text:"고객번호", value: 'CUSTOMER_PHONE_NO', align: 'center', sortable: false, },
        {text:"고객명", value: 'CUSTOMER_NM', align: 'center', sortable: false, },
        {text:"주민/사업자번호", value: 'RES_NO', align: 'center', sortable: false, }, //주민번호 뒷자리 가리기
        {text:"기본주소", value: 'OWNHOM_ADR', align: 'center', sortable: false, },
        {text:"휴대전화번호", value: 'CUSTOMER_PHONE_NO', align: 'center', sortable: false, },
        {text:"자택전화번호", value: 'OWNHOM_TEL_NO', align: 'center', sortable: false, },
        {text:"직장전화번호", value: 'WRC_TEL_NO', align: 'center', sortable: false, },
      ],
      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,

      ASP_CUST_KEY:this.$store.getters['userStore/GE_USER_ROLE'].company,

      PHONE_NUM: [
        {
          CD: '',
          CD_NM: '전체'
        },
        {
          CD: '010',
          CD_NM: '010'
        },
        {
          CD: '011',
          CD_NM: '011'
        },
        {
          CD: '016',
          CD_NM: '016'
        },
        {
          CD: '017',
          CD_NM: '017'
        },
        {
          CD: '018',
          CD_NM: '018'
        },
        {
          CD: '019',
          CD_NM: '019'
        },
      ],

      ASP_NEWCUST_KEY: '',
      CUS_NEWNM:'',
      SRH_SIM: '',
      CUS_NEWBIRTH:'',
      PHONE_NEWNUM1: '',
      PHONE_NEWNUM2: '',
      PHONE_NEWNUM3: '',
    };
  },

  created() {
    this.ASP_NEWCUST_KEY = this.cusInfo.ASP_CUST_KEY;
    this.CUS_NEWNM = this.cusInfo.CUST_NM;
    this.ASP_CUST_KEY = this.$store.getters['userStore/GE_USER_ROLE'].company;
    if(this.cusInfo.PHONE_NUM.length == 11){
      this.PHONE_NEWNUM1 = this.cusInfo.PHONE_NUM.substr(0,3);
      this.PHONE_NEWNUM2 = this.cusInfo.PHONE_NUM.substr(3,4);
      this.PHONE_NEWNUM3 = this.cusInfo.PHONE_NUM.substr(6,4);
    } else if(this.cusInfo.PHONE_NUM.length == 10){
      this.PHONE_NEWNUM1 = this.cusInfo.PHONE_NUM.substr(0,3);
      this.PHONE_NEWNUM2 = this.cusInfo.PHONE_NUM.substr(3,3);
      this.PHONE_NEWNUM3 = this.cusInfo.PHONE_NUM.substr(5,4);
    }
    this.selectCus();
  },

  methods: {
    init(){
      this.ASP_NEWCUST_KEY = this.cusInfo.ASP_CUST_KEY;

      this.CUS_NEWNM='';
      this.SRH_SIM='';
      this.CUS_NEWBIRTH='';
      this.PHONE_NEWNUM1= '';
      this.PHONE_NEWNUM2= '';
      this.PHONE_NEWNUM3= '';
    },

    selectCus(){
      var CHK_SEARCH = "";
      if (this.SRH_SIM == false || this.SRH_SIM == "" || this.SRH_SIM == null) {
        CHK_SEARCH = "";
      } else {
        CHK_SEARCH = this.SRH_SIM;
      }
      api.post("api/setting/customer/info-list/cstmrinfo-inqire-popup/inqire",   //api url입력
      {
        ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
        fldInqCustNm: this.CUS_NEWNM,
        fldInqCustIdfNo: this.CUS_NEWBIRTH,
        checkCF01: CHK_SEARCH,
        cmbInqTano: this.PHONE_NEWNUM1,
        fldInqTgno: this.PHONE_NEWNUM2,
        fldInqTino: this.PHONE_NEWNUM3,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "setting.customer.info-list.cstmrinfo-inqire-popup",
        "METHOD" : "inqire",
        "TYPE" : "BIZ_SERVICE",
        "ROW_CNT" : "200",
        "PAGES_CNT" : "1",

      }})
      .then((response) => {
        console.log(response.data.DATA);
        this.gridDataText = response.data.DATA;
        for(var i=0; i<this.gridDataText.length;i++){
          this.gridDataText[i]["index"]= i+1;
        }
        this.page=1;
      })
      .catch((err) => {
        alert(err);
      })
    },

    //Table row 클릭이벤트
    rowClick2(e, { item }) {
      this.cusInfo.CUST_NO = item.CUSTOMER_ID;
      this.cusInfo.CUST_NM = item.CUSTOMER_NM;
      this.cusInfo.CUSTOMER_MEMO = item.CUSTOMER_MEMO;
      this.cusInfo.CUSTOMER_PHONE_NO = item.CUSTOMER_PHONE_NO;
      this.cusInfo.EMAIL = item.EMAIL;
      this.cusInfo.OWNHOM_ADR = item.OWNHOM_ADR;
      this.cusInfo.OWNHOM_DETAIL_ADR = item.OWNHOM_DETAIL_ADR;
      this.cusInfo.ATENT_CUSTOMER = item.ATENT_CUSTOMER;

      //this.cusInfo.EXTRAADDRESS = item.EXTRAADDRESS;
      //this.cusInfo.POST_CODE = item.POST_CODE;

      this.$emit("selectCus", this.cusInfo);
      this.init();
      this.selectCus();
    },

    //Table row 클릭이벤트
    rowClick1(item, row) {
      if(item != null){
        this.activeRow(row,true);
      }
    },

    activeRow(row, value){
      console.log(row);
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

  },
  mounted(){
  },
  computed: {},
  watch: {
    cusInfo(){
      this.init()

      this.ASP_CUST_KEY = this.$store.getters['userStore/GE_USER_ROLE'].company;
      this.ASP_NEWCUST_KEY = this.cusInfo.ASP_CUST_KEY;
      this.CUS_NEWNM = this.cusInfo.CUST_NM;
      if(this.cusInfo.PHONE_NUM.length == 11){
        this.PHONE_NEWNUM1 = this.cusInfo.PHONE_NUM.substr(0,3);
        this.PHONE_NEWNUM2 = this.cusInfo.PHONE_NUM.substr(3,4);
        this.PHONE_NEWNUM3 = this.cusInfo.PHONE_NUM.substr(6,4);
      } else if(this.cusInfo.PHONE_NUM.length == 10){
        this.PHONE_NEWNUM1 = this.cusInfo.PHONE_NUM.substr(0,3);
        this.PHONE_NEWNUM2 = this.cusInfo.PHONE_NUM.substr(3,3);
        this.PHONE_NEWNUM3 = this.cusInfo.PHONE_NUM.substr(5,4);
      }
      this.selectCus();
    }
  }
};
</script>

<style></style>
